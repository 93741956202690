import { render, staticRenderFns } from "./pa-records.vue?vue&type=template&id=7e991629&scoped=true"
import script from "./pa-records.vue?vue&type=script&lang=ts"
export * from "./pa-records.vue?vue&type=script&lang=ts"
import style0 from "./pa-records.vue?vue&type=style&index=0&id=7e991629&prod&lang=scss"
import style1 from "./pa-records.vue?vue&type=style&index=1&id=7e991629&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e991629",
  null
  
)

export default component.exports